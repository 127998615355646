import React from 'react';
import { Seo, OrderSuccess, Feed } from 'components';
const projects = require('./../../content/categories/projects.json');

export default () => {
  return (
    <>
      <Seo title={'Request'} />
      <OrderSuccess />
      <Feed
        list={projects.feed.map((item) => {
          return ['projects', item].join('/');
        })}
      />
    </>
  );
};
